<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch"
              >
                <v-select
                  v-model="branch"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="branchoption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Movable</label>
              <v-select
                v-model="movable"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="None"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Name of Asset</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name of Asset"
              >
                <b-form-input
                  v-model="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name of Asset"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Ledger</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Ledger"
              >
                <v-select
                  v-model="ledger"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="ledgeroption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Make</label>
              <b-form-input
                v-model="make"
                type="text"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Make"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>HSN Code</label>
              <b-form-input
                v-model="hsncode"
                type="number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter HSN Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>CGST %</label>
              <b-form-input
                v-model="cgst"
                type="number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter CGST %"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>SGST %</label>
              <b-form-input
                v-model="sgst"
                type="number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter SGST %"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>IGST %</label>
              <b-form-input
                v-model="igst"
                type="number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter IGST %"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from '@/components/axios';
import moment from "moment";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      branch: "",
      branchoption: [],
      movable: "",
      name: "",
      ledger: "",
      ledgeroption: [],
      make: "",
      hsncode: "",
      cgst: "",
      sgst: "",
      igst: "",
      ifEdit: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      flag: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Asset") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/assetmaster");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/assetmaster");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getassetmasterById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.branch = this.getBranch(item.branch);
        this.movable = item.movable;
        this.name = item.name;
        this.ledger = this.getLedger(item.ledger);
        this.make = item.make;
        this.hsncode = item.hsncode;
        this.cgst = item.cgst;
        this.sgst = item.sgst;
        this.igst = item.igst;
      });
    },
    init() {
      this.getBranch();
      this.getLedger();
    },
    async getBranch(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/branch`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.branchoption = response.data.data.data;
          this.branchoption.map((item) => {
            if (item.id == id) {
              this.branch = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLedger(id) {
      if (id !== undefined) {
        this.id = id;
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getLedger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.ledgeroption = response.data.data;
          this.ledgeroption.map((item) => {
            if (item.id == id) {
              this.ledger = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/master/hr/assetmaster");
    },
    submitForm(e) {
      const data = {
        branch: this.branch.id,
        movable: this.movable,
        name: this.name,
        ledger: this.ledger.id,
        make: this.make,
        hsncode: this.hsncode,
        cgst: this.cgst,
        sgst: this.sgst,
        igst: this.igst,
        make: this.make,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/assetmaster/${this.$route.params.id}`
              : `${baseApi}/assetmaster`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.flag = false;

              this.$router.push("/master/hr/assetmaster");
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>

